export function getSocialMetas({
  url,
  title = 'DataSnipper',
  description = 'Ready, set, snip!',
  image = 'https://eu-images.contentstack.com/v3/assets/bltc08aa646f32b9827/bltb70ebcca766221d7/home-page-seo-image.png',
  keywords,
}: {
  image?: string
  url: string
  title?: string
  description?: string
  keywords?: string
}) {
  return [
    { title },
    { name: 'description', content: description },
    { name: keywords, content: keywords },
    { name: 'image', content: image },
    { name: 'og:url', content: url },
    { name: 'og:title', content: title },
    { name: 'og:description', content: description },
    { name: 'og:image', content: image },
    {
      name: 'twitter:card',
      content: image ? 'summary_large_image' : 'summary',
    },
    { name: 'twitter:creator', content: '@datasnipper' }, // TODO: check if we want this and the @ is correct
    { name: 'twitter:site', content: '@datasnipper' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    { name: 'twitter:alt', content: title },
  ]
}
