import { CsComponent } from './component.tsx'

import { Page } from '#app/graphql/@generated/api.ts'

export function CsPage({ entry, preview }: { entry: Page; preview: boolean }) {
  return (
    <main
      data-contenttype="page"
      data-pageref={entry.system?.uid}
      data-locale={entry.system?.locale}
    >
      {(entry.blocks ?? []).map((block, index) =>
        block ? (
          <CsComponent
            key={index}
            entry={Object.values(block)[0]}
            preview={preview}
          />
        ) : null,
      )}
    </main>
  )
}
